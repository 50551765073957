<template>
  <div class="info-container not-clickable">
    <div class="info-header clickable">
      <div class="info-header__left">
        <v-btn class="go-back-btn clickable" @click="onGoBack" fixed icon large color="#fff">
          <v-icon size="36">mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <div class="info-header_center" style="display: flex; justify-content: center; align-items: center">
        <span class="info-header_city">{{ cityName }}</span>
      </div>
      <div class="info-header__right">{{ getDate }}</div>
    </div>

    <div class="info-footer clickable">
      <div class="info-footer__left">
        <router-link :to="{ name: 'Occasions' }">
          <img class="logo" :src="require('@/assets/logo/logo-choreograph-exact.svg')" alt="Choreograph" />
        </router-link>
      </div>
      <div class="info-footer__center"></div>
      <div class="info-footer__right">
        <div class="experiences clickable">
          <div style="display: flex; justify-content: center; align-items: center">
            <router-link class="go-to-experience" :to="{ name: 'SelectExperience' }"> Launch Experiences </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="info-content clickable">
      <div class="occasion">
        <div class="occasion__info">
          <div class="occasion__info-header">
            <div class="occasion__header-container ellipsis">
              <img
                class="occasion-info__icon"
                :src="require(`@/assets/icons/icons-48x48/${getIcon(occasion.occasion_name)}.svg`)"
                :alt="occasion.occasion_title.toLowerCase()"
              />
              <span class="occasion__title ellipsis pr-4">{{ occasionTitle }}</span>
            </div>
            <div class="mt-2">
              <Donut
                :occasionIndex="occasion.occasion_index"
                :donut-size="30"
                :donut-thickness="32"
                donut-background-color="#21212b"
              />
            </div>
          </div>
          <div class="occasion__category-container">
            <div class="occasion__category" v-for="category in occasionCategories" :key="category.title">
              <span class="occasion__category-type">{{ category.title }}</span>
              <span class="occasion__category-value">{{ occasion[category.key] }}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="experiences-container">
          <div v-for="item in qrCodes" :key="item.file" class="usual-experience">
            <h2>{{ item.title }}</h2>
            <div class="d-flex justify-center">
              <img width="164" height="164" :src="item.file" alt="Please, scan the QR." />
            </div>
          </div>
          <div @click="openBehindTheScene" class="usual-experience behind-the-scene">
            <h2>BEHIND THE SCENES</h2>
          </div>
        </div>
      </div>
      <div style="width: 100%; display: flex; justify-content: center"></div>
    </div>
    <v-dialog v-model="showBehindTheScene">
      <video v-if="showBehindTheScene" class="behind-the-scene-video" autoplay controls>
        <source :src="behindTheSceneVideo" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { timeout } from "@/utils/misc";
import { getDateString } from "@/utils/dateTime";
import Donut from "@/components/Donut";
import { sliderOccasionIconMapping } from "@/assets/icons/occasions-sprite";

import "swiper/css/swiper.css";
import { FILE_STORAGE_URL } from "@/settings";

export default {
  name: "ExperienceMultiQr",
  components: { Donut },
  data() {
    return {
      occasionCategories: [
        {
          title: "strategy",
          key: "context",
        },
        {
          title: "context",
          key: "choice_driver",
        },
        {
          title: "segmentation",
          key: "need_state",
        },
      ],
      showBehindTheScene: false,
    };
  },
  created() {
    this.setIsShowMap(false);
    this.setGlobeMap(false);
    this.videoLoaded();
  },

  async beforeMount() {
    if (this.occasion === undefined) {
      this.$router.replace("/");
    }
    await timeout(2000);
    this.setComposeLoading(false);
  },

  beforeDestroy() {
    this.setIsLoadingVideo(true);
    this.setComposeLoading(true);
    this.setIsShowMap(true);
  },

  computed: {
    ...mapGetters("occasions", ["selectedOccasion"]),

    getDate() {
      return getDateString();
    },

    cityName() {
      return this.selectedOccasion?.occasion?.cityId?.toUpperCase();
    },

    occasion() {
      return this.selectedOccasion?.occasion?.occasions?.[0] || undefined;
    },

    qrCodes() {
      let data = [];
      const qrCodes = [];
      if (this.occasion?.qr?.length) {
        data = this.occasion.qr;
      }
      data.forEach((el) => {
        if (el.type === "image") {
          qrCodes.push({
            title: el.title.toUpperCase(),
            file: `${FILE_STORAGE_URL}/${el.file}`,
          });
        }
      });
      return qrCodes;
    },

    behindTheSceneVideo() {
      if (this.occasion?.qr?.length) {
        const el = this.occasion.qr.find((el) => el.type === "video");
        if (!el) {
          return null;
        }
        return `${FILE_STORAGE_URL}/${el.file}`;
      } else {
        return null;
      }
    },

    occasionTitle() {
      return this.occasion?.occasion_title?.toLowerCase() || undefined;
    },
  },

  methods: {
    ...mapMutations("app", ["setGlobeMap", "setIsShowMap"]),
    ...mapMutations("compose", ["setComposeLoading"]),
    ...mapMutations("selectExperience", ["setIsLoadingVideo"]),

    onGoBack() {
      this.$router.go(-1);
    },

    getIcon(occasionName) {
      return sliderOccasionIconMapping[occasionName];
    },

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    videoLoaded() {
      this.setIsLoadingVideo(false);
    },
    openBehindTheScene() {
      this.showBehindTheScene = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-header_city {
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-top: 10px;
}
.occasion {
  display: flex;
  justify-content: center;
  &__info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__info {
    display: flex;
    flex-direction: column;
    padding: 16px 32px 30px;
    background: rgba(33, 33, 43, 0.48);
    backdrop-filter: blur(40px);
    border: 1px solid #21212b;
    width: 646px;
  }
  &__title {
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
  }
  .occasion-info__icon {
    vertical-align: middle;
    padding-right: 14px;
  }
  &__header-container {
    display: flex;
    align-items: center;
    padding-top: 12px;
  }
  &__category:first-of-type {
    margin-top: 12px;
  }
  &__category {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    border-top: 1px solid #454549;
    padding-bottom: 16px;
  }
  &__category:last-child {
    padding-bottom: 0;
  }
  &__category-type,
  &__category-value {
    align-self: flex-start;
  }
  &__category-type {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.24), 0 1px 2px rgba(0, 0, 0, 0.4);
  }
  &__category-value {
    padding-top: 8px;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
  }
}
.category-container {
  display: flex;
  flex-direction: column;
}
.info-content {
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;
}
.experiences-container {
  color: white;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 10px;
}
.usual-experience {
  border: 1px solid #21212b;
  padding: 20px;
  margin-right: 20px;
  width: 314px;
  height: 314px;
  h2 {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }
  img {
    margin-top: 30px;
  }
}
.behind-the-scene {
  cursor: pointer;
  h2 {
    font-size: 20px;
  }
  background-image: url("~@/assets/images/behind-the-scene.svg");
  background-position-y: bottom;
  background-size: 100%;
}
::v-deep {
  .v-dialog {
  }
}
.behind-the-scene-video {
  @media (max-width: 2000px) {
    height: 800px;
  }
  @media (max-width: 1921px) {
    height: 750px;
  }
  @media (max-width: 1367px) {
    height: 710px;
  }
}
</style>
