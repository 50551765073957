<template>
  <div class="info-container not-clickable occasions-choices">
    <div class="info-header">
      <div class="info-header__left">
        <div class="clickable">Occasions</div>
      </div>
      <div class="info-header_center"></div>
      <div class="info-header__right">
        <div class="clickable">{{ getDate }}</div>
      </div>
    </div>

    <div class="info-footer">
      <div class="info-footer__left"></div>
      <div class="info-footer__center data-sources-nav-container">
        <DataSources class="clickable" />
      </div>
      <div class="info-footer__right">
        <div class="experiences clickable">
          <div>
            <router-link class="go-to-experience" :to="{ name: 'SelectExperience' }"> Launch Experiences </router-link>
          </div>
          <v-btn
            v-if="isUserStopAnimation"
            class="play-globe-btn clickable"
            @click="onPlayRotate"
            fixed
            icon
            large
            color="#fff"
          >
            <v-icon>mdi-television-play</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div class="occasions-choices__outer-wrapper clickable">
      <div class="occasions-choices__wrapper">
        <div class="occasions-choices__filter-container">
          <p class="occasions-choices__filter-title">Strategy</p>
          <label
            class="checkbox occasions-choices__filter-option"
            @change="handleClick(item, 'context')"
            v-for="item in occasionCategories.contexts"
            :key="item"
          >
            <input :disabled="isCheckboxDisabled(item, 'context')" type="checkbox" :value="item" v-model="context" />
            <div class="checkbox-square square-checked">
              <v-icon class="icon" dense color="#fff">mdi-check</v-icon>
            </div>
            <div class="checkbox-square square-unchecked"></div>
            {{ item }}</label
          >
        </div>
        <div class="occasions-choices__filter-container">
          <p class="occasions-choices__filter-title">Segmentation</p>
          <label
            class="checkbox occasions-choices__filter-option"
            @change="handleClick(item, 'needStates')"
            v-for="item in occasionCategories.needStates"
            :key="item"
          >
            <input
              :disabled="isCheckboxDisabled(item, 'needStates')"
              type="checkbox"
              :value="item"
              v-model="needStates"
            />
            <div class="checkbox-square square-checked">
              <v-icon class="icon" dense color="#fff">mdi-check</v-icon>
            </div>
            <div class="checkbox-square square-unchecked"></div>
            {{ item }}</label
          >
        </div>
        <div class="occasions-choices__filter-container">
          <p class="occasions-choices__filter-title">Context</p>
          <label
            class="checkbox occasions-choices__filter-option"
            @change="handleClick(item, 'choiceDrivers')"
            v-for="item in occasionCategories.choiceDrivers"
            :key="item"
          >
            <input
              :disabled="isCheckboxDisabled(item, 'choiceDrivers')"
              type="checkbox"
              :value="item"
              v-model="choiceDrivers"
            />
            <div class="checkbox-square square-checked">
              <v-icon class="icon" dense color="#fff">mdi-check</v-icon>
            </div>
            <div class="checkbox-square square-unchecked"></div>
            {{ item }}</label
          >
        </div>
      </div>
      <div class="occasions-choices__reset-btn clickable" @click="resetFilters">reset filters</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import { filterDataOccasions } from "@/utils/filters";
import { getRandomListFromList } from "@/utils/random";
import { getAllOccasions } from "@/helpers/occasions";

import DataSources from "@/components/DataSources";

export default {
  name: "Occasion",
  components: {
    DataSources,
  },

  data() {
    return {
      occasions: [],
      context: ["all"],
      needStates: ["all"],
      choiceDrivers: ["all"],
      occasionPoints: [],
    };
  },

  beforeMount() {
    this.setGlobeMap(true);
    this.showPlateOccasions();
  },

  async mounted() {
    await this.getOccasion();
    await this.getCitiesData();
    await this.initialize();
    this.setInitialFilters();
    this.updateFilterOccasions();
  },

  computed: {
    ...mapGetters("occasions", ["occasionCategories", "filteredOccasionPoints", "citiesData", "filter"]),
    ...mapGetters("mainGlobe", ["isUserStopAnimation", "citiesOccasionsData"]),

    getDate() {
      const today = new Date();
      const month = today.toLocaleString("en-US", { month: "long" });
      const day = today.getDate();
      const year = today.getFullYear();
      return day + " " + month + " " + year;
    },
  },

  methods: {
    ...mapActions("occasions", ["getOccasion", "getCitiesData"]),
    ...mapMutations("mainGlobe", ["setMapLoaded", "onPlayRotate", "showPlateOccasions"]),
    ...mapMutations("occasions", ["setFilter", "setSelectedOccasion", "setFilteredOccasionPoints"]),
    ...mapMutations("app", ["setGlobeMap"]),

    async initialize() {
      const occasionsPerCity = 10;

      this.occasionPoints = this.citiesData.map((point) => ({
        ...point,
        id: `${point.city} ${point.lat} ${point.long}`,
        occasions: getRandomListFromList(getAllOccasions(this.citiesOccasionsData), occasionsPerCity),
      }));
    },

    filterUpdate() {
      this.setFilter({
        context: this.context,
        needStates: this.needStates,
        choiceDrivers: this.choiceDrivers,
      });
    },

    updateFilterOccasions() {
      this.filterUpdate();

      this.setFilteredOccasionPoints(
        filterDataOccasions(this.occasionPoints, {
          context: this.context[0] === "all" ? [] : this.context,
          needStates: this.needStates[0] === "all" ? [] : this.needStates,
          choiceDrivers: this.choiceDrivers[0] === "all" ? [] : this.choiceDrivers,
        })
      );

      console.log("FILTERED OCCASIONS:", this.filteredOccasionPoints.length);
    },

    handleClick(option, filterType) {
      if (this[filterType].includes("all") && option === "all") {
        this[filterType] = ["all"];
      }
      if (this[filterType].indexOf("all") !== -1) {
        this[filterType] = [option];
      }
      if (!this[filterType].length) {
        this[filterType] = ["all"];
      }

      this.updateFilterOccasions();
    },

    resetFilters() {
      this.context = ["all"];
      this.needStates = ["all"];
      this.choiceDrivers = ["all"];

      this.updateFilterOccasions();
    },

    setInitialFilters() {
      this.context = this.filter.context;
      this.needStates = this.filter.needStates;
      this.choiceDrivers = this.filter.choiceDrivers;
    },

    isCheckboxDisabled(item, filterType) {
      return item === "all" && this[filterType][0] === "all";
    },
  },
};
</script>

<style scoped lang="scss">
.data-sources-nav-container {
  align-items: flex-end;
}

.play-globe-btn {
  position: absolute;
  bottom: 4px;
  right: 20px;
}

.occasions-choices {
  color: #fff;

  .v-icon.v-icon--dense {
    font-size: 24px;
  }

  &__outer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    margin-top: 105px;
    width: 230px;
    background-color: #00000073;
    backdrop-filter: blur(4px);
    padding: 0 24px;

    .occasions-choices__wrapper {
      height: calc(100% - 110px);
      overflow-y: scroll;

      /* Fix for Safari scroll */
      padding: 0 1px;
      margin: 0 -1px;

      .occasions-choices__filter-container {
        display: flex;
        flex-direction: column;
        padding: 12px 0;
      }

      .occasions-choices__filter-title {
        display: flex;
        border-bottom: 1px solid white;
        padding-bottom: 13px;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
      }

      .occasions-choices__filter-option {
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        cursor: pointer;

        label {
          font-size: 16px;
          line-height: 120%;
          text-transform: lowercase;
        }
      }
    }
  }

  &__sources-container {
    width: 100%;
    position: absolute;
    bottom: 0;

    .occasions-choices__sources {
      width: 260px;
      height: 54px;
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      background: #262626f2;
      bottom: 0;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      text-transform: uppercase;
      margin: 0 auto;

      .occasions-choices__sources-icon {
        color: white;
        margin-left: 6px;
      }
    }
  }

  &__reset-btn {
    display: flex;
    color: white;
    background: #262626f2;
    height: 52px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 20px;
  }

  .v-input--selection-controls__input {
    order: 2;
  }

  .v-input--selection-controls:first-of-type {
    margin-top: 2px;
  }

  .checkbox {
    user-select: none;

    .checkbox-square {
      order: 2;
      width: 28px;
      height: 28px;
      border: solid 1px #ffffff;
      flex-shrink: 0;
      margin-left: 4px;

      &.square-checked {
        display: none;
      }

      &.square-unchecked {
        border-color: #898383;
        display: block;
      }
    }

    input[type="checkbox"] {
      display: none;
    }

    input:checked + .square-checked {
      display: block;
    }

    input:checked + .square-checked + .square-unchecked {
      display: none;
    }
  }
}
</style>
