export const data = {
  "initial pain recognition": [
    {
      cityId: "berlin",
      lat: 52.52,
      long: 13.40495,
      occasions: [
        {
          occasion_name: "workout",
          occasion_title: "Weekend exercise",
          occasion_index: 94,
          context: "Initial pain recognition",
          need_state: "Insecure Solution Seekers",
          choice_driver: "Exercise",
          city: "berlin",
          qr: [
            {
              type: "iframe",
              iframe: "https://storage.googleapis.com/pitchworkpublic/PITCH/HALEON/loaders/loader_berlin_voltaren.html",
            },
          ],
        },
      ],
    },
    {
      cityId: "mumbai",
      lat: 19.0825223,
      long: 72.7411014,
      occasions: [
        {
          occasion_name: "on the move",
          occasion_title: "Long road trip",
          occasion_index: 93,
          context: "Initial pain recognition",
          need_state: "Insecure Solution Seekers",
          choice_driver: "Travel",
          city: "mumbai",
          qr: [
            {
              type: "iframe",
              iframe: "https://storage.googleapis.com/pitchworkpublic/PITCH/HALEON/loaders/loader_mumbai_voltaren.html",
            },
          ],
        },
      ],
    },
  ],
  "treatment decision making": [
    {
      cityId: "stockholm",
      lat: 59.3293,
      long: 18.0686,
      occasions: [
        {
          occasion_name: "lunch",
          occasion_title: "Shopping for music festival",
          occasion_index: 91,
          context: "Treatment decision making",
          need_state: "Confident Pain Managers",
          choice_driver: "Shopping",
          city: "stockholm",
          qr: [
            {
              type: "iframe",
              iframe:
                "https://storage.googleapis.com/pitchworkpublic/PITCH/HALEON/loaders/loader_stockholm_panadol.html",
            },
          ],
        },
      ],
    },
    {
      cityId: "hong kong",
      lat: 22.3027,
      long: 114.1772,
      occasions: [
        {
          occasion_name: "commute to home",
          occasion_title: "Shopping for travel essentials",
          occasion_index: 92,
          context: "Treatment decision making",
          need_state: "Confident Pain Managers",
          choice_driver: "Shopping",
          city: "hong kong",
          qr: [
            {
              type: "iframe",
              iframe:
                "https://storage.googleapis.com/pitchworkpublic/PITCH/HALEON/loaders/loader_honkkong_panadol.html",
            },
          ],
        },
      ],
    },
  ],
  "managing symptoms": [
    {
      cityId: "san francisco",
      lat: 37.7749,
      long: -122.4194,
      occasions: [
        {
          occasion_name: "snack time",
          occasion_title: "Fever from cold and flu",
          occasion_index: 93,
          context: "Managing Symptoms",
          need_state: "Efficacy Seekers",
          choice_driver: "Recovery",
          city: "san francisco",
          qr: [
            {
              type: "iframe",
              iframe:
                "https://storage.googleapis.com/pitchworkpublic/PITCH/HALEON/loaders/loader_sanfracisco_theraflu.html",
            },
          ],
        },
      ],
    },
    {
      cityId: "warsaw",
      lat: 52.2297,
      long: 21.0122,
      occasions: [
        {
          occasion_name: "snack time",
          occasion_title: "Fever from cold and flu",
          occasion_index: 91,
          context: "Managing Symptoms",
          need_state: "Efficacy Seekers",
          choice_driver: "Recovery",
          city: "warsaw",
          qr: [
            {
              type: "iframe",
              iframe: "https://storage.googleapis.com/pitchworkpublic/PITCH/HALEON/loaders/loader_warsaw_theraflu.html",
            },
          ],
        },
      ],
    },
  ],
};

export const occasionDictionary = {
  workout: "Early Workout",
  breakfast: "Healthy Work Breakfast",
  "on the move": "Commute Pick Me Up",
  "eating out": "Social Dinner Out",
  studying: "Evening Study Break",
  working: "WFH Break",
  "pause at work": "Healthy Afternoon Snack",
  "social media": "Social Scrolling",
  "going out": "Team Sports",
  "watching a movie": "Movie Night",
  "playing video games online": "Social Gaming",
  diner: "Easy Solo Dinner",
  socialising: "Brunch with Friends",
  "binge watching series": "Screentime Unwind",
  gaming: "Gaming Break",
  "snack time": "Afternoon Pick Me Up",
  "pause at": "Morning Study Break",
  "watching an e-sport game": "Social eSports",
  "me time": "Solo Shopping",
  "commute to university": "Eating On the Go",
  "commute to home": "On the Way Home",
  "live event": "Gig Night Out",
  lunch: "Everyday Lunch Together",
  "snack at university": "After School Treat",
};
