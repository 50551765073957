export const getPredefinedCitiesDetails = async () => require("./citiesDetails.json");

export const getPredefinedCitiesOccasions = async () => require("./citiesOccasions.json");

export const getPredefinedCities = async () => {
  return [
    {
      cityId: "berlin",
      name: "Berlin",
      lat: 52.52,
      long: 13.40495,
      tz: "Europe/Berlin",
      group: "europe",
      region: "EMEA",
    },
    {
      cityId: "bogota",
      name: "Bogota",
      lat: 4.6243,
      long: -74.0636,
      tz: "America/Bogota",
      group: "america",
      region: "LATAM",
    },
    {
      cityId: "florence",
      name: "Florence",
      lat: 43.7695,
      long: 11.2558,
      tz: "Europe/Rome",
      group: "europe",
      region: "EMEA",
    },
    {
      cityId: "guadalajara",
      name: "Guadalajara",
      lat: 20.6597,
      long: -103.3496,
      tz: "America/Mexico_City",
      group: "america",
      region: "LATAM",
    },
    {
      cityId: "hong kong",
      name: "Hong Kong",
      lat: 22.3027,
      long: 114.1772,
      tz: "Asia/Hong_Kong",
      group: null,
      region: "APAC",
    },
    {
      cityId: "kuala lumpur",
      name: "Kuala Lumpur",
      lat: 3.1319,
      long: 101.6841,
      tz: "Asia/Kuala_Lumpur",
      group: "asia",
      region: "APAC",
    },
    {
      cityId: "london",
      name: "London",
      lat: 51.5072,
      long: -0.1276,
      tz: "Europe/London",
      group: "europe",
      region: "EMEA",
    },
    {
      cityId: "mumbai",
      name: "Mumbai",
      lat: 19.0825223,
      long: 72.7411014,
      tz: "Asia/Kolkata",
      group: null,
      region: "ASIA",
    },
    {
      cityId: "paris",
      name: "Paris",
      lat: 48.8566,
      long: 2.3522,
      tz: "Europe/Paris",
      group: "europe",
      region: "EMEA",
    },
    {
      cityId: "rio de janeiro",
      name: "Rio de Janeiro",
      lat: -22.9068,
      long: -43.1729,
      tz: "America/Sao_Paulo",
      group: "america",
      region: "LATAM",
    },
    {
      cityId: "riyadh",
      name: "Riyadh",
      lat: 24.7136,
      long: 46.6753,
      tz: "Asia/Riyadh",
      group: null,
      region: "EMEA",
    },
    {
      cityId: "san francisco",
      name: "San Francisco",
      lat: 37.7749,
      long: -122.4194,
      tz: "America/Los_Angeles",
      group: "america",
      region: "NA",
    },
    {
      cityId: "shanghai",
      name: "Shanghai",
      lat: 31.2304,
      long: 121.4737,
      tz: "Asia/Shanghai",
      group: "asia",
      region: "APAC",
    },
    {
      cityId: "sydney",
      name: "Sydney",
      lat: 33.8688,
      long: 151.2093,
      tz: "Australia/Sydney",
      group: null,
      region: "APAC",
    },
    {
      cityId: "stockholm",
      name: "Stockholm",
      lat: 59.3293,
      long: 18.0686,
      tz: "Europe/Stockholm",
      group: "europe",
      region: "EMEA",
    },
    {
      cityId: "warsaw",
      name: "Warsaw",
      lat: 52.2297,
      long: 21.0122,
      tz: "Europe/Warsaw",
      group: "europe",
      region: "EMEA",
    },
  ];
};

export const getPredefinedCitiesDict = async () => {
  const cities = await getPredefinedCities();
  const citiesDict = {};

  cities.forEach((city) => {
    citiesDict[city.cityId] = city;
  });

  return citiesDict;
};
