<template>
  <div class="info-container not-clickable">
    <v-btn class="go-back-btn clickable" @click="onGoBack" fixed icon large color="#fff">
      <v-icon size="36">mdi-chevron-left</v-icon>
    </v-btn>

    <div class="info-footer clickable">
      <div class="info-footer__left">
        <router-link :to="{ name: 'Occasions' }">
          <img class="logo" :src="require('@/assets/images/choreograph.svg')" alt="Choreograph" />
        </router-link>
      </div>
      <div class="info-footer__center"></div>
      <div class="info-footer__right">
        <v-switch v-model="isChecked" color="#fff" inset></v-switch>
      </div>
    </div>

    <div class="info-content clickable">
      <div class="select-experience-container-outer">
        <div class="select-experience-container">
          <div class="select-experience-header">
            <span class="select-experience-header__date" style="margin-left: auto">{{ getDate }}</span>
          </div>
          <div class="select-experience-title">Select Experience</div>
          <div class="select-experience-card-container">
            <div class="select-experience-card select-experience-card">
              <div
                style="height: calc(100% - 64px); display: flex; flex-direction: column; width: 100%"
                @click="changeRoute('initial pain recognition')"
              >
                <div class="select-experience-card-title">
                  <!-- TODO: What is the icon that we should use?
                  <span class="select-experience-card-icon">
                    <img :src="require('@/assets/icons/icons-56x56/workout-experience.svg')" />
                  </span>
                  -->
                  <span class="select-experience-card-text">Initial Pain Recognition</span>
                </div>
                <div class="select-experience-card-image-wrapper">
                  <div class="select-experience-card-image-content">
                    <img
                      :src="require('@/assets/icons/experience/voltaren-logo.png')"
                      class="select-experience-card-image"
                    />
                  </div>
                </div>
                <div>
                  <div class="go-to">
                    <img :src="require('@/assets/icons/arrow-right.svg')" alt="Sync" />
                  </div>
                </div>
              </div>
            </div>
            <div class="select-experience-card">
              <div
                style="height: calc(100% - 64px); display: flex; flex-direction: column; width: 100%"
                @click="changeRoute('treatment decision making')"
              >
                <div class="select-experience-card-title">
                  <!-- TODO: What is the icon that we should use?
                  <span class="select-experience-card-icon">
                    <img :src="require('@/assets/icons/icons-56x56/music-experience.svg')" />
                  </span>
                  -->
                  <span class="select-experience-card-text">Treatment Decision Making</span>
                </div>
                <div class="select-experience-card-image-wrapper">
                  <div class="select-experience-card-image-content">
                    <img
                      :src="require('@/assets/icons/experience/panadol-logo.png')"
                      class="select-experience-card-image"
                    />
                  </div>
                </div>
                <div class="select-experience-body">
                  <div class="go-to">
                    <img :src="require('@/assets/icons/arrow-right.svg')" alt="Sync" />
                  </div>
                </div>
              </div>
            </div>
            <div class="select-experience-card">
              <div
                style="height: calc(100% - 64px); display: flex; flex-direction: column; width: 100%"
                @click="changeRoute('managing symptoms')"
              >
                <div class="select-experience-card-title">
                  <!-- TODO: What is the icon that we should use?
                  <span class="select-experience-card-icon">
                    <img :src="require('@/assets/icons/icons-56x56/gaming-experience.svg')" />
                  </span>
                  -->
                  <span class="select-experience-card-text">Managing Symptoms</span>
                </div>
                <div class="select-experience-card-image-wrapper">
                  <div class="select-experience-card-image-content">
                    <img
                      :src="require('@/assets/icons/experience/theraflu-logo.png')"
                      class="select-experience-card-image"
                    />
                  </div>
                </div>
                <div class="select-experience-body">
                  <div class="go-to">
                    <img :src="require('@/assets/icons/arrow-right.svg')" alt="Sync" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { getDateString } from "@/utils/dateTime";
import { data } from "@/store/modules/selectExperience/mockData";

export default {
  name: "SelectExperience",

  data() {
    return {
      // TODO: What is this?
      switch1: true,
    };
  },

  async beforeMount() {
    this.setGlobeMap(false);
    await timeout(1000);
    this.setSelectExperienceLoading(false);
  },

  beforeDestroy() {
    this.changeIsInitialLoading(true);
    this.setSelectExperienceLoading(true);
  },

  computed: {
    ...mapGetters("selectExperience", ["isExperienceChecked"]),

    getDate() {
      return getDateString();
    },

    isChecked: {
      get() {
        return this.isExperienceChecked;
      },

      set(val) {
        this.setExperienceChecked(val);
      },
    },
  },

  methods: {
    ...mapMutations("app", ["setGlobeMap", "changeIsInitialLoading"]),
    ...mapMutations("selectExperience", [
      "setSelectExperienceLoading",
      "setExperienceChecked",
      "setSelectedExperience",
    ]),
    ...mapMutations("mainGlobe", ["setVisibleCities"]),

    onGoBack() {
      this.$router.go(-1);
    },

    changeRoute(categoryName) {
      this.setVisibleCities(data[categoryName]);
      this.setSelectedExperience(categoryName);
      this.$router.push({ name: "ExperienceCategory" });
    },
  },
};
import "swiper/css/swiper.css";

import { timeout } from "@/utils/misc";
</script>

<style scoped lang="scss">
.select-experience-container-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 80px;
}

.select-experience-container {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);

  .select-experience-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 36px;
    padding-top: 32px;

    &__date {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 24px;
      font-weight: 400;
    }
  }
}

.select-experience-title {
  font-weight: 300;
  font-size: 80px;
  line-height: 96px;
  margin-top: 58px;
  margin-bottom: 0;
  padding: 0 50px;
}

.select-experience-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1px;
  margin-top: 62px;
  height: 100%;
  cursor: pointer;
}

.select-experience-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 0 0 0;
  background: rgba(255, 255, 255, 0.12);
  align-items: flex-start;
}

.select-experience-card--active {
  background: rgba(255, 255, 255, 0.48);
}

.select-experience-card-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  padding: 0 12px 24px;
  justify-content: center;
  height: 128px;
}

.select-experience-card-icon {
  display: flex;
  width: 48px;
  height: 48px;
  margin-right: 16px;
}

.select-experience-card-image-wrapper {
  position: relative;
  width: 60%;
  height: 100%;
  max-width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .select-experience-card-image-content {
    width: 100%;
    padding-bottom: 100%;
    background-color: black;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select-experience-card-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    width: 80%;
  }
}

.select-experience-card-text {
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 0.02em;
  width: 100%;
  text-align: center;
}

.select-experience-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.go-to {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 64px;
  height: 64px;
  background-color: #8d8d8d;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-height: 50px;
  }
}
</style>
