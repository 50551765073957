import Hashes from "jshashes";

const SHA256 = new Hashes.SHA256();

const USERNAME_HASH = "0a73205117a7f3e69d6026fea56be8b8a22132d55b02bf38711fb08ef954e201";
const PASSWORD_HASH = "a61e2854f3c11a9099da3d8319d101941e091c9fbc90e0d0dbac4920cebf6121";

export default {
  namespaced: true,

  state: {
    authorized: false,
    user: null,
    loginError: "",
  },

  mutations: {
    setUser(state, user) {
      state.authorized = !!user;
      state.user = user;
    },

    setLoginError(state, message) {
      state.loginError = message;
    },
  },

  actions: {
    async login(ctx, params) {
      const { username, password } = params;

      if (SHA256.hex(username) === USERNAME_HASH && SHA256.hex(password) === PASSWORD_HASH) {
        ctx.commit("setUser", { username });
      } else {
        ctx.commit("setUser", null);
        ctx.commit("setLoginError", "Email address/password do not match any existing accounts.");
      }
    },

    async logout(ctx) {
      ctx.commit("setUser", null);
    },
  },

  getters: {
    user(state) {
      return state.user;
    },

    authorized(state) {
      return state.authorized;
    },

    loginError(state) {
      return state.loginError;
    },
  },
};
