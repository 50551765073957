<template>
  <div class="info-container not-clickable">
    <div class="info-header clickable">
      <div class="info-header__left">
        <v-btn class="go-back-btn clickable" @click="onGoBack" fixed icon large color="#fff">
          <v-icon size="36">mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <div class="info-header_center" style="display: flex; justify-content: center; align-items: center">
        <span class="info-header_city">{{ cityName }}</span>
      </div>
      <div class="info-header__right">{{ getDate }}</div>
    </div>

    <div class="info-footer clickable">
      <div class="info-footer__left">
        <router-link :to="{ name: 'Occasions' }">
          <img class="logo" :src="require('@/assets/logo/logo-choreograph-exact.svg')" alt="Choreograph" />
        </router-link>
      </div>
      <div class="info-footer__center"></div>
      <div class="info-footer__right">
        <div class="experiences clickable">
          <div style="display: flex; justify-content: center; align-items: center">
            <router-link class="go-to-experience" :to="{ name: 'SelectExperience' }"> Launch Experiences </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="info-content clickable" style="flex-direction: column; max-width: 100%">
      <div style="display: flex; justify-content: center; max-width: 100%">
        <div class="occasion">
          <div class="occasion__info">
            <div class="occasion__info-header">
              <div class="occasion__header-container ellipsis">
                <img
                  class="occasion-info__icon"
                  :src="require(`@/assets/icons/icons-48x48/${getIcon(occasion.occasion_name)}.svg`)"
                  :alt="occasion.occasion_title.toLowerCase()"
                />
                <span class="occasion__title ellipsis">{{ occasionTitle }}</span>
              </div>
              <Donut
                :occasionIndex="occasion.occasion_index"
                :donut-size="30"
                :donut-thickness="32"
                donut-background-color="#21212b"
              />
            </div>
            <div class="occasion__category-container">
              <div class="occasion__category" v-for="category in occasionCategories" :key="category.title">
                <span class="occasion__category-type">{{ category.title }}</span>
                <span class="occasion__category-value">{{ occasion[category.key] }}</span>
              </div>
            </div>
          </div>
          <iframe v-if="qr.iframe" :src="qr.iframe" class="occasion__iframe" width="478" height="456" />
          <video v-else-if="qr.file" class="occasion__video" width="478" height="456" autoplay>
            <source :src="qr.file" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div style="width: 100%; display: flex; justify-content: center"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { timeout } from "@/utils/misc";
import { getDateString } from "@/utils/dateTime";
import Donut from "@/components/Donut";
import { sliderOccasionIconMapping } from "@/assets/icons/occasions-sprite";

import "swiper/css/swiper.css";

export default {
  name: "ExperienceSingleQr",
  components: { Donut },
  data() {
    return {
      occasionCategories: [
        {
          title: "strategy",
          key: "context",
        },
        {
          title: "segmentation",
          key: "need_state",
        },
        {
          title: "context",
          key: "choice_driver",
        },
      ],
    };
  },
  created() {
    this.setIsShowMap(false);
    this.setGlobeMap(false);
  },

  async beforeMount() {
    window.addEventListener("load", this.onVideoLoad());
    if (this.occasion === undefined) {
      await this.$router.replace("/");
    }
    await timeout(2000);
    this.setComposeLoading(false);
  },

  beforeDestroy() {
    this.setIsLoadingVideo(true);
    this.setComposeLoading(true);
    this.setIsShowMap(true);
  },

  computed: {
    ...mapGetters("occasions", ["selectedOccasion"]),

    getDate() {
      return getDateString();
    },

    cityName() {
      return this.selectedOccasion?.occasion?.cityId?.toUpperCase();
    },

    occasion() {
      return this.selectedOccasion?.occasion?.occasions?.[0] || undefined;
    },

    qr() {
      return this.occasion?.qr?.[0] || undefined;
    },

    occasionTitle() {
      return this.occasion?.occasion_title?.toLowerCase() || undefined;
    },
  },

  methods: {
    ...mapMutations("app", ["setGlobeMap", "setIsShowMap"]),
    ...mapMutations("compose", ["setComposeLoading"]),
    ...mapMutations("selectExperience", ["setIsLoadingVideo"]),

    onGoBack() {
      this.$router.go(-1);
    },

    getIcon(occasionName) {
      return sliderOccasionIconMapping[occasionName];
    },

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    async onVideoLoad() {
      await timeout(700);
      this.setIsLoadingVideo(false);
      window.removeEventListener("load", this.onVideoLoad);
    },
  },
};
</script>

<style scoped lang="scss">
.info-header_city {
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.occasion {
  display: flex;
  justify-content: space-around;
  &__info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__info,
  &__qr {
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
    background: rgba(33, 33, 43, 0.48);
    backdrop-filter: blur(40px);
    border: 1px solid #5b5b5b;
    width: 468px;
    height: 456px;
  }
  &__info {
    margin-right: 24px;
  }
  &__title {
    font-weight: 300;
    font-size: 28px;
    line-height: 44px;
    width: 100%;
  }
  .occasion-info__icon {
    vertical-align: middle;
    padding-right: 12px;
  }
  &__video {
    border: 1px solid #5b5b5b;
    width: 48%;
  }
  &__header-container {
    display: flex;
    align-items: center;
  }
  &__category:first-of-type {
    margin-top: 54px;
  }
  &__category {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    border-top: 2px solid #454549;
    padding-bottom: 54px;
  }
  &__category-type,
  &__category-value {
    align-self: flex-start;
  }
  &__category-type {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 1px 2px rgba(0, 0, 0, 0.4);
  }
  &__category-value {
    padding-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  &__video {
    height: 456px;
    max-width: 100%;
  }

  &__iframe {
    border: 1px solid #5b5b5b;
  }
}
.category-container {
  display: flex;
  flex-direction: column;
}
.info-content {
  justify-content: center !important;
}
</style>
