import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";
import Login from "@/components/Login";
import Demo from "@/layouts/Demo";
import Occasions from "@/layouts/Occasions";
import CityChoices from "@/layouts/CityChoices";
import City from "@/layouts/City";
import SyncDiagram from "@/layouts/SyncDiagram";
import Compose from "@/layouts/Compose";
import SelectExperience from "@/layouts/SelectExperience";
import ExperienceCategory from "@/layouts/ExperienceCategory";
import ExperienceMultiQr from "@/layouts/ExperienceMultiQr";
import ExperienceSingleQr from "@/layouts/ExperienceSingleQr.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Demo,
    children: [
      {
        path: "/",
        name: "Occasions",
        component: Occasions,
      },
      {
        path: "/city-choices",
        name: "CityChoices",
        component: CityChoices,
      },
      {
        path: "/city",
        name: "City",
        component: City,
      },
      {
        path: "/sync-diagram",
        name: "SyncDiagram",
        component: SyncDiagram,
      },
      {
        path: "/compose",
        name: "Compose",
        component: Compose,
      },

      {
        path: "/select-experience",
        name: "SelectExperience",
        component: SelectExperience,
      },

      {
        path: "/experience-category",
        name: "ExperienceCategory",
        component: ExperienceCategory,
      },

      {
        path: "/experience-single-qr",
        name: "ExperienceSingleQr",
        component: ExperienceSingleQr,
      },

      {
        path: "/experience-multi-qr",
        name: "ExperienceMultiQr",
        component: ExperienceMultiQr,
      },
    ],
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

const LOGIN_ROUTE = { name: "Login" };

router.beforeEach(async (to, from, next) => {
  if (to.name !== LOGIN_ROUTE.name && !store.getters["user/authorized"]) {
    next(LOGIN_ROUTE);
  } else {
    next();
  }
});

export default router;
