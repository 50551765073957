var render = function render(){var _vm=this,_c=_vm._self._c;return _c('canvas',{ref:"sphereMap",style:({
    width: '100%',
    'background-color': 'black',
    'background-image': `url(${require('@/assets/images/stars-foff.jpg')})`,
    'background-size': '100% auto',
    'background-position': 'center',
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }