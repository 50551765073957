<template>
  <div
    style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center"
    class="clickable city-container"
  >
    <div class="city-occasions">
      <div class="city-occasions__header">
        <span class="city-occasions__header__region"> {{ regionName }} </span>
        <span class="city-occasions__header__date">{{ getDate }}</span>
      </div>

      <div class="city-occasions__title">{{ selectedOccasion.occasion.name }}</div>

      <div class="swiper-block">
        <swiper ref="swiper" @click="onClick" :options="{ ...swiperOption, initialSlide: activeSlide }">
          <swiper-slide
            v-for="(slide, index) in occasionsList"
            :key="slide.occasion_name + index"
            :data-index="index + 1"
            class="clickable"
          >
            <div class="slide-content">
              <div class="slide-header">
                <img
                  class="slide-header__icon"
                  :src="require(`@/assets/icons/icons-48x48/${getIcon(slide.occasion_name)}.svg`)"
                  :alt="slide.occasion_title.toLowerCase()"
                />
                <div class="slide-header__title ellipsis">{{ slide.occasion_title.toLowerCase() }}</div>
              </div>

              <div class="slide-occasion" v-for="occasion in occasions" :key="occasion.key">
                <span class="slide-occasion__category">
                  {{ occasion.title }}
                </span>
                <div class="slide-occasion__value ellipsis">
                  {{ slide[occasion.key] }}
                </div>
              </div>

              <div class="slide-occasion-index">
                <span class="slide-occasion-index__value">growth index</span>
                <Donut :occasionIndex="slide.occasion_index" />
              </div>
            </div>

            <div class="go-to-sync">
              <div class="arrow-container">
                <img :src="require('@/assets/icons/arrow-right.svg')" alt="Sync" />
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <v-btn class="go-back-btn clickable" @click="onGoBack" fixed icon large color="#fff">
      <v-icon size="36">mdi-chevron-left</v-icon>
    </v-btn>
    <router-link class="go-home-btn" :to="{ name: 'Occasions' }">
      <img :src="require('@/assets/images/choreograph.svg')" alt="Choreograph" />
    </router-link>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { getDateString } from "@/utils/dateTime";
import Donut from "@/components/Donut";
import { sliderOccasionIconMapping } from "@/assets/icons/occasions-sprite";
import "swiper/css/swiper.css";
import { INITIAL_ACTIVE_SLIDE } from "@/store/modules/occasions/occasions";

export default {
  name: "City",
  components: { Swiper, SwiperSlide, Donut },

  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 12,
        loop: true,
        speed: 700,
        centeredSlides: true,
        showsPagination: false,
        preventClicks: false,
        preventClicksPropagation: false,
        slideToClickedSlide: true,
        touchRatio: 3,
        longSwipesRatio: 0.2,
        resistanceRatio: 0.3,
        initialSlide: INITIAL_ACTIVE_SLIDE,
        threshold: 5,
      },

      occasions: {
        context: { title: "strategy", key: "context" },
        needState: { title: "segmentation", key: "need_state" },
        choiceDriver: { title: "context", key: "choice_driver" },
      },
    };
  },

  created() {
    this.setGlobeMap(false);
    if (
      !this.selectedOccasion?.occasion?.cityId ||
      !this.selectedOccasion?.occasion?.points.length ||
      !this.selectedOccasion?.occasion?.occasions.length
    ) {
      this.$router.replace("/");
    }
  },

  beforeDestroy() {
    this.$refs.swiper.$swiper.destroy();

    this.changeIsInitialLoading(true);
  },

  computed: {
    ...mapGetters("occasions", ["selectedOccasion", "activeSlide"]),

    getDate() {
      return getDateString();
    },

    regionName() {
      return this.selectedOccasion?.occasion?.region?.toUpperCase();
    },

    occasionsList() {
      let occasions = this.selectedOccasion?.occasion?.occasions;

      if (!occasions) {
        return;
      }

      return [occasions[0], ...occasions.slice(1, 7).reverse()];
    },
  },

  methods: {
    ...mapMutations("app", ["setGlobeMap", "changeIsInitialLoading"]),
    ...mapMutations("occasions", ["setActiveSlideOccasion", "setActiveSlide"]),

    onGoBack() {
      this.$router.go(-1);
    },

    getIcon(occasionName) {
      // TODO: It is necessary to set an icon even the occasion icon is not found. It is "workout" so far.
      return sliderOccasionIconMapping[occasionName]?.toLowerCase() || "workout";
    },

    onClick(event) {
      if (event.target.closest(".go-to-sync")) {
        event.stopPropagation();

        const slideIndex =
          Number(event.target.closest(".swiper-slide")?.getAttribute("data-swiper-slide-index")) ||
          INITIAL_ACTIVE_SLIDE;
        const currentOccasion = this.occasionsList[slideIndex] || {};

        this.setActiveSlideOccasion(currentOccasion);
        this.setActiveSlide(slideIndex);

        this.$router.push("/sync-diagram");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.city-occasions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ffffff36;
  color: white;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.5);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 42px 36px 0;

    &__region {
      font-size: 36px;
      font-weight: 400;
    }

    &__date {
      font-size: 24px;
      font-weight: 400;
    }
  }

  &__title {
    margin: -12px 36px 10px 36px;
    font-weight: 300;
    font-size: 104px;
  }

  .swiper-block {
    width: 1092px;
    color: white;
    padding-bottom: 10px;

    .swiper-slide {
      box-sizing: border-box;
      width: 350px;
      height: 466px;
      align-self: flex-end;
      background-color: rgba(255, 255, 255, 0.15);
      padding: 36px 16px 32px;
      transition: background-color 0.5s;

      &.swiper-slide-active {
        background-color: rgba(255, 255, 255, 0.32);
        padding-left: 20px;
        padding-right: 20px;

        .slide-title {
          font-weight: 300;
          line-height: 44px;
        }

        .go-to-sync {
          display: flex;
        }
      }

      /* TODO: Prev and Next slide shadows was removed by the request. Remove classes later. */
      &.swiper-slide-prev {
        .slide-shadow {
          -webkit-mask-image: linear-gradient(90deg, #00000010, #000000aa 90%, #000000ff);
          mask-image: linear-gradient(90deg, black, transparent 100%);
        }
      }
      &.swiper-slide-next {
        .slide-shadow {
          -webkit-mask-image: linear-gradient(270deg, #00000010, #000000aa 90%, #000000ff);
          mask-image: linear-gradient(270deg, black, transparent 100%);
        }
      }

      .go-to-sync {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 52px;
        height: 52px;
        background-color: #8d8d8d;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .arrow-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-height: 50px;
          }
        }
      }

      .slide-content {
        .slide-header {
          display: flex;
          margin-bottom: 12px;

          &__icon {
            height: 42px;
            padding-right: 8px;
          }

          &__title {
            font-weight: 300;
            font-size: 24px;
            line-height: 44px;
          }
        }

        .slide-occasion {
          display: flex;
          flex-direction: column;
          padding-top: 8px;
          padding-bottom: 18px;
          border-top: 1px solid rgba(255, 255, 255, 0.12);

          &__category {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.24), 0 1px 2px rgba(0, 0, 0, 0.4);
          }

          &__value {
            padding-top: 8px;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
          }
        }

        .slide-occasion-index {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 16px;
          border-top: 1px solid rgba(255, 255, 255, 0.12);

          &__value {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            letter-spacing: 0.04em;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.go-back-btn {
  width: 80px !important;
  height: 80px !important;
  margin-left: 24px;
  border: 1px solid #fff;
  border-radius: 0;
  position: absolute;
  left: 0;
}

.go-home-btn {
  position: absolute;
  bottom: 30px;
  left: 30px;
  img {
    height: 50px;
  }
}
</style>
