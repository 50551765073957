import { render, staticRenderFns } from "./SyncBox.vue?vue&type=template&id=549b337c&scoped=true&"
import script from "./SyncBox.vue?vue&type=script&lang=js&"
export * from "./SyncBox.vue?vue&type=script&lang=js&"
import style0 from "./SyncBox.vue?vue&type=style&index=0&id=549b337c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "549b337c",
  null
  
)

export default component.exports